import React, { createContext, useState } from 'react'

const ModalContext = createContext(true)

export function ModalProvider({ children }) {
  const [visitedFirstTime, setVisitedFirstTime] = useState(true)
  const [open, setOpen] = useState(false)
  const value = { visitedFirstTime, setVisitedFirstTime, open, setOpen }
  return <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
}

export default ModalContext
